import React, {Component, ReactElement} from "react";
import "./historyEntry.css";
import TaggedImage from "./taggedImage";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import Expand from "./expand";


export interface HistoryEntryProps {
    image? : string;
    title: string;
    link? : string;
    description: string|Node|ReactElement|Node[]|ReactElement[];
    subEntries: SubEntryDetails[];
    parentKey?: string;
    contrastImageBackground? : boolean;
    expandable? : boolean;
}

export interface SubEntryDetails {
    subtitle? : string;
    dateRange : string;
    sterileDateRange? : string;
    image?: string;
    fontawesome?: IconDefinition;
}

export default class HistoryEntry extends Component<HistoryEntryProps> {

    constructor(props: HistoryEntryProps) {
        super(props);
        this.redirect = this.redirect.bind(this);
    }

    redirect() {
        window.location.href = this.props.link!;
    }



    getSubEntryTitle(c : SubEntryDetails) : ReactElement {
        if (c.subtitle) {
            return <span>{c.subtitle}</span>
        } else {
            return <div></div>
        }
    }

    getSubEntryDetails() : ReactElement[] {
        return this.props.subEntries.map(c => <div className="HistoryEntry-SubEntry" key={this.props.parentKey+"-SubEntry-"+(c.subtitle? c.subtitle.replace(/\s+/g, ''):"blank")}>
            <TaggedImage image={c.image} fontawesome={c.fontawesome} body={<span>{c.sterileDateRange ? c.sterileDateRange : c.dateRange}</span>}/>
            <div  className={this.props.subEntries.length > 1 ? "HistoryEntry-SubEntry-Title" : "HistoryEntry-SubEntry-Title-Full"} >{this.getSubEntryTitle(c)}</div>
        </div>);
    }

    getLogo() : ReactElement {
        let wrapperClasses : string = "HistoryEntry-ImageWrapper";
        if (this.props.contrastImageBackground === true) {
            wrapperClasses += " HistoryEntry-ContrastingImageWrapper";
        }
        if (this.props.expandable === true) {
            wrapperClasses += " HistoryEntry-ExpandableTitle"
        } else {
            wrapperClasses += " HistoryEntry-FixedTitle"
        }
        if (this.props.image) {
            if (this.props.link) {
                return <div className={wrapperClasses + " HistoryEntry-Clickable"} onClick={this.redirect}><img src={process.env.PUBLIC_URL + "/logos/"+this.props.image} alt={this.props.title}/><h3>{this.props.title}</h3></div>
            }
            return <div className={wrapperClasses}><img src={process.env.PUBLIC_URL + "/logos/"+this.props.image} alt={this.props.title}/><h3>{this.props.title}</h3></div>
        } else {
            return <div className={wrapperClasses}><h3>{this.props.title}</h3></div>
        }
    }

    render() {
        if (this.props.expandable === true) {
            return <div className="HistoryEntry">
                <Expand defaultExpansion={false}
                                 key={"HistoryEntry-Expansion-"+this.props.title}
                                 header={this.getLogo()}
                                 content={
                                     <div>
                                         <div className="HistoryEntry-SubtEntry-Wrapper">{this.getSubEntryDetails()}</div>
                                         <div className="HistoryEntry-Description">{this.props.description}</div>
                                     </div>
                                 }
                />
            </div>;
        } else {
            return (<div className="HistoryEntry">
                <div className="HistoryEntry-Content">
                    {this.getLogo()}
                    <div className="HistoryEntry-SubtEntry-Wrapper">{this.getSubEntryDetails()}</div>
                    <div className="HistoryEntry-Description">{this.props.description}</div>
                </div>
            </div>)
        }
    }
}