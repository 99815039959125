import React, {Component} from "react";
import SkillGroup from "./skillgroup";
import History from "./history";
import Header from "./header";

export default class Cv extends Component<{}> {


    constructor(props : {}) {
        super(props)
        this.updateComponentHeights = this.updateComponentHeights.bind(this);
    }

    updateComponentHeights(newExpansionState : boolean) {
        // This can be used to readjust history section height based on skill based section expansion
        // but since history is longer than skill section atm this is not needed



        // let skillGroupElement : Element = document.getElementsByClassName('SkillGroup')[0];
        // let historyElement : Element = document.getElementsByClassName('History')[0];
        // let newSkillGroupHeight : number = historyElement.clientHeight;
        // // we now add the padding-top
        // newSkillGroupHeight += this.getTopPadding(skillGroupElement);
        // console.log("h Height", historyElement.clientHeight, "s Height", skillGroupElement.clientHeight, " h padd", this.getTopPadding(historyElement), " s padd",this.getTopPadding(skillGroupElement), "calc", newSkillGroupHeight);
        //
        // skillGroupElement.setAttribute("style","height:"+newSkillGroupHeight+"px");
        // // skillGroupElement.setAttribute("style","height:"+Math.max(skillGroupElement.clientHeight, newHistoryHeight)+"px");

    }

    getTopPadding(element : Element) : number {
        return Number(window.getComputedStyle(element, null).getPropertyValue('padding-top').slice(0, -2));
    }

    render() {
        return (
            <div className="Cv-Wrapper">
                <header>
                    <Header/>
                </header>
                <SkillGroup onExpansionChange={this.updateComponentHeights}/>
                <History/>
            </div>);
    }
}