import React, {Component, ReactElement} from "react";
import "./expand.css"

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusSquare, faPlusSquare} from "@fortawesome/free-solid-svg-icons";

const expandClosed = <FontAwesomeIcon icon={faPlusSquare}/>;
const expandOpen = <FontAwesomeIcon icon={faMinusSquare}/>;

export type ExpansionCallback = (newExpansionState : boolean) => void;

export interface ExpandProperties {
    header : Node|ReactElement|Node[]|ReactElement[],
    content : Node|ReactElement|Node[]|ReactElement[],
    defaultExpansion: boolean,
    onExpansionChange: ExpansionCallback,
    colouredTop: boolean
}

interface ExpandState {
    expanded: boolean
}

export default class Expand extends Component<ExpandProperties, ExpandState> {

    static defaultProps : ExpandProperties = {
        header: <div/>,
        content: <div/>,
        defaultExpansion: true,
        colouredTop: false,
        onExpansionChange: (b) => {}
    }

    state : ExpandState = {
        expanded: this.props.defaultExpansion
    }

    constructor(props: ExpandProperties) {
        super(props);
        this.toggleExpansion = this.toggleExpansion.bind(this);
    }


    toggleExpansion() {
        console.log("toggle")
        this.setState({
            expanded: !this.state.expanded
        })
    }

    componentDidMount() {
        if (this.props.onExpansionChange) {
            this.props.onExpansionChange(!this.state.expanded)
        }
    }

    componentDidUpdate(prevProps: Readonly<ExpandProperties>, prevState: Readonly<ExpandState>, snapshot?: any) {
        if (this.props.onExpansionChange) {
            this.props.onExpansionChange(!this.state.expanded)
        }
    }

    componentWillUnmount() {
        if (this.props.onExpansionChange) {
            this.props.onExpansionChange(!this.state.expanded)
        }
    }

    getTopClasses() :string {
        let classes : string = "Expand-Top";
        if (this.props.colouredTop === true) {
            classes += " ExpandTop-Coloured";
        }
        return classes;
    }

    render () {
        return <div className="Expand">
            <div className={this.getTopClasses()} onClick={this.toggleExpansion}>
                <div className="Expand-Button">
                    {this.state.expanded ? expandOpen : expandClosed}
                </div>
                <div className="Expand-Title" >{this.props.header}</div>
            </div>
            {this.state.expanded ? <div className="Expand-Bottom">
                                                {this.props.content}
                                    </div>
                                 : null}

        </div>;
    }
}
