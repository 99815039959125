import React, {Component, useEffect} from "react";
import "./skillgroup.css";
import Expand, {ExpansionCallback} from "./expand";
import TaggedImage from "./taggedImage";
import ProgressBar, {ProgressBarProps} from "../representative/progressbar";
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';
import {faDatabase, faPhone} from '@fortawesome/free-solid-svg-icons';
import {
    faAndroid,
    faAngular,
    faApple,
    faAppStoreIos,
    faCentos,
    faCss3Alt,
    faDocker,
    faGit,
    faGitlab,
    faHtml5,
    faJava,
    faJenkins,
    faJs,
    faNodeJs,
    faReact,
    faUbuntu,
    faWindows
} from '@fortawesome/free-brands-svg-icons';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

interface SkillData extends ProgressBarProps{
    url?: string,
    image?: string
    fontawesome? : IconDefinition;
    name: string,
}

interface SkillCategory {
    name: string,
    data: SkillData[]
}

interface ContactData {
    image? : string;
    fontawesome? :IconDefinition;
    link : string;
    text: string;
}
interface SkillGroupProps {
    onExpansionChange: ExpansionCallback
}

interface SkillGroupState {
    pendingVerification: boolean,
    verified: boolean,
    passedVerification: boolean
}

interface WrapperProps {
    didUpdate: Function;
}

export const Observer = ({didUpdate} : WrapperProps) => {
    useEffect(() => {
        didUpdate()
    }, []);
    return null;
};

interface ReCaptchaResponse {
    success: boolean,
    score: number,
    action: string,
    challenge_ts: string,
    hostname: string
}

export default class SkillGroup extends Component<SkillGroupProps, SkillGroupState> {


    static linkedInUrl = "https://www.linkedin.com/in/aaron-axisa";
    static linkedInUrlShort = "www.linkedin.com/in/aaron-axisa";
    static captchaKey = '6LcAGbIaAAAAAL_nI_zsW1tjOMCNS7UttzsgSsxA';


    static contactData: ContactData[] = [
        {image: "linkedin.svg", link: SkillGroup.linkedInUrl, text: SkillGroup.linkedInUrlShort},
        {fontawesome: faEnvelope, link: "mailto:aaron@aaronaxisa.com", text: "aaron@aaronaxisa.com"},
        {fontawesome: faPhone, link: "tel:+35679992517", text: "+356 79992517"},
    ];

    static skills: SkillCategory[] = [
        {
            name: "Backend",
            data: [
                {fontawesome: faJava, name: "Java 18", progress: 100},
                {image: "spring.png", name: "Spring Framework", progress: 90},
                {image: "quarkus.png", name: "Quarkus", progress: 80},
                {image: "mq.png", name: "Rabbit / Zero MQ, Kafka", progress: 60},
                {image: "akka.png", name: "Akka", progress: 40},
                {image: "jersey.png", name: "Jersey", progress: 40},
                {image: "jooq.png", name: "Jooq", progress: 80},
                {image: "hibernate.png", name: "Hibernate", progress: 70},
                {image: "tomcat.png", name: "Apache Tomcat", progress: 70},
                {image: "maven.png", name: "Maven", progress: 100},
                {image: "rest.png", name: "REST", progress: 100},
                {image: "soap.png", name: "SOAP", progress: 40},
                {image: "dotnet.png", name: "C#.NET", progress: 20},
            ]
        },
        {
            name: "Frontend",
            data: [
                {fontawesome: faReact, name: "ReactJS", progress: 90},
                {fontawesome: faJs, name: "Javascript", progress: 90},
                {image: "ts.png", name: "Typescript", progress: 90},
                {fontawesome: faHtml5, name: "HTML", progress: 80},
                {fontawesome: faCss3Alt, name: "CSS", progress: 60},
                {fontawesome: faNodeJs, name: "NodeJS", progress: 60},
                {fontawesome: faAngular, name: "Angular", progress: 70},
                {image: "jsf.png", name: "JSF", progress: 40},
                {image: "jquery.png", name: "JQuery", progress: 50},
                {image: "wpf.png", name: "WPF", progress: 20},

            ]
        },
        {
            name: "Mobile",
            data: [
                {fontawesome: faAndroid, name: "Flutter", progress: 40}
            ]
        },
        {
            name: "DevOps",
            data: [
                {image: "nginx.png", name: "Nginx", progress: 80},
                {image: "ansible.png", name: "Ansible", progress: 70},
                {image: "puppet.png", name: "Puppet", progress: 50},
                {fontawesome: faDocker, name: "Docker", progress: 80},
                {fontawesome: faDocker, name: "Helm", progress: 70},
                {image: "terraform.png", name: "Hashicorp Terraform", progress: 30},
                {image: "vagrant.png", name: "Hashicorp Vagrant", progress: 70},
                {image: "kubernetes.png", name: "Kubernetes", progress: 70},
                {image: "compose.png", name: "Docker Compose", progress: 70},
                {image: "gcp.png", name: "GCP", progress: 70},
                {image: "msstore.png", name: "Microsoft Store", progress: 20},
            ]
        },
        {
            name: "Databases",
            data: [
                {fontawesome: faDatabase, name: "PostgreSQL, MySQL", progress: 70},
                {image: "mongo.png", name: "MongoDB", progress: 80},
            ]
        },
        {
            name: "Utilities",
            data: [
                {image: "eclipse.png", name: "Eclipse", progress: 70},
                {image: "intellij.png", name: "Jetbrains IntelliJ IDEA", progress: 100},
                {image: "webstorm.png", name: "Jetbrains Webstorm", progress: 90},
                {image: "postman.png", name: "Postman", progress: 60},
                {image: "sonar.png", name: "SonarQube", progress: 60},
                {image: "nexus.png", name: "Sonatype Nexus", progress: 80},
                {image: "vault.svg", name: "Hashicorp Vault", progress: 70},
                {fontawesome: faJenkins, name: "Jenkins", progress: 70},
                {image: "sheets.png", name: "Google Sheets", progress: 100},
                {image: "gscript.svg", name: "Google Scripts", progress: 80},
            ]
        },
        {
            name: "Version Control",
            data: [
                {fontawesome: faGit, name: "Git", progress: 100},
                {image: "hg.png", name: "Mercurial", progress: 60},
                {image: "svn.png", name: "Subversion", progress: 60},
                {fontawesome: faGitlab, name: "Gitlab", progress: 90},
            ]
        },
        {
            name: "Graphical",
            data: [
                {image: "ps.png", name: "Photoshop", progress: 40},
                {image: "affinity.png", name: "Affinity Design", progress: 40},
                {image: "ps.png", name: "Graphical Design", progress: 10},
            ]
        },
        {
            name: "OS",
            data: [
                {fontawesome: faWindows, name: "Windows", progress: 100},
                {fontawesome: faCentos, name: "CentOS", progress: 90},
                {fontawesome: faUbuntu, name: "Ubuntu", progress: 80},
                {fontawesome: faApple, name: "OSX", progress: 70},
                {fontawesome: faAndroid, name: "Android", progress: 100},
                {fontawesome: faAppStoreIos, name: "iOS", progress: 60},
            ]
        },
    ];


    constructor(props: SkillGroupProps) {
        super(props);
        this.captchaVerified = this.captchaVerified.bind(this);
        this.consumeCaptchaToken = this.consumeCaptchaToken.bind(this);
        this.viewContactData = this.viewContactData.bind(this);
        this.forceCaptchaCheck = this.forceCaptchaCheck.bind(this);
        this.state = {
            pendingVerification: false,
            verified: false,
            passedVerification: false
        };
    }

    forceCaptchaCheck() : any {
        if (!this.state.verified) {
            this.viewContactData();
        }
    }



    getContactData() {
        return <div className="SkillGroup-Content" key={"SkillGroup-ContactData"}>
            <div className="SkillGroup-Header" key={"SkillGroup-Contact-Header"}>Contact Details</div>
            {this.getSecureContactData()}
        </div>;
    }

    getSecureContactData() {
        if (this.state.verified) {
            if (this.state.passedVerification) {
                return SkillGroup.contactData.map(d => <div
                    key={"SkillGroup-Contact-" + (d.image ? d.image : d.text.replace(/\s+/g, '').replace('@', ''))}
                    className="SkillGroup-ContentBody"><TaggedImage addImageBackground={true} image={d.image}
                                                                    fontawesome={d.fontawesome} imageLink={d.link}
                                                                    body={<div><span>&nbsp;</span><a
                                                                        href={d.link}>{d.text}</a></div>}/></div>);
            } else {
                return <div className="SkillGroupViewContactButtonWrapper"><span className={"SkillGroupCaptchaFailure"}>Could not verify reCaptcha!</span></div>;
            }
        } else {
            if (this.state.pendingVerification) {
                return <div>
                    <div className={"SkillGroupViewContactButtonWrapper"}>
                        <GoogleReCaptchaProvider reCaptchaKey={SkillGroup.captchaKey}>
                            <GoogleReCaptcha onVerify={this.consumeCaptchaToken}/>
                        </GoogleReCaptchaProvider>
                        <div className={"SkillGroupViewContactLoading"}>Making sure you're not a robot...</div>
                    </div>
                </div>;
            } else {
                return <div>
                        <Observer didUpdate={this.forceCaptchaCheck}/>
                        <div className={"SkillGroupViewContactButtonWrapper"}>
                            <button className={"rcc-confirm-button SkillGroupViewContactButton"}
                                    onClick={this.viewContactData}>View
                            </button>
                        </div>
                    </div>
            }
        }
    }

    viewContactData() {
        this.setState({
            pendingVerification: true,
            verified: false
        })
    }

    captchaVerified(data : any) {
        this.setState({
            pendingVerification: false,
            verified: true,
            passedVerification: data.valid,
        })
    }



    consumeCaptchaToken(token: string) {
        fetch('https://api.ipify.org/')
            .then(response => response.text())
            .then(b => {
                fetch ('https://captchacheckfunction-o3qyavvdxq-oa.a.run.app/capthcacheck', {
                    method: 'post',
                    body: JSON.stringify({token:token, userip: b}),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(this.captchaVerified)
            })

    }


    getSkillData() {
        return SkillGroup.skills.map(c => <div className="SkillGroup-Content"
                                               key={"SkillGroup-Data-" + c.name.replace(/\s+/g, '')}>
            <Expand defaultExpansion={true}
                    key={"SkillGroup-Content-Expand" + c.name.replace(/\s+/g, '')}
                    header={<span>{c.name}</span>}
                    onExpansionChange={this.props.onExpansionChange}
                    colouredTop={true}
                    content={c.data.sort((a, b) => a.progress > b.progress ? -1 : b.progress > a.progress ? 1 : a.name > b.name ? 1 : -1)
                        .map(s => <TaggedImage
                            key={"SkillGroup-Content-Expand-" + c.name.replace(/\s+/g, '') + "-" + s.name.replace(/\s+/g, '')}
                            addImageBackground={true} image={s.image} fontawesome={s.fontawesome} imageLink={s.url}
                            body={<div>
                                <span>&nbsp;</span>
                                <span className="SkillGroup-IndividualHeader">
                            {s.url ? s.name : <a href={s.url}>{s.name}</a>}
                        </span>
                                <ProgressBar progress={s.progress}/>
                            </div>}/>)}
            />
        </div>)
    }

    render() {
        return (<div className="SkillGroup">
            <div className="SkillGroup-Name" key={"SkillGroup-Name"}>Aaron Axisa</div>
            {this.getContactData()}
            <div className="SkillGroup-Header" key={"SkillGroup-Skills"}>Skills</div>
            {this.getSkillData()}
        </div>);
    }
}

