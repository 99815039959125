import React, {Component, ReactElement} from "react";
import "./taggedImage.css";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


export interface TaggedImageProps {
    image? : string
    fontawesome? : IconDefinition
    imageLink? : string
    body : Node | ReactElement
    addImageBackground?: boolean
}

export default class TaggedImage extends Component<TaggedImageProps> {

    constructor(props: TaggedImageProps) {
        super(props);
        this.redirect = this.redirect.bind(this);
    }

    redirect() {
        window.location.href = this.props.imageLink!;
    }


    getImageClass() {
        if (this.props.addImageBackground === true) {
            return "TaggedImage-ImageWrapper";
        }
        return "TaggedImage-ImageWrapper-NoBackground";
    }

    getImageBody() : ReactElement {
        if (this.props.imageLink) {
            return <div className={this.getImageClass() + " TaggedImage-Clickable"} onClick={this.redirect}>{this.getImageElement()}</div>
        } else {
            return <div className={this.getImageClass()}>{this.getImageElement()}</div>
        }
    }

    getImageElement() : ReactElement {
        if (this.props.image) {
            return <img src={process.env.PUBLIC_URL + "/logos/" + this.props.image} alt={this.props.image}/>;
        } else if (this.props.fontawesome) {
            return <FontAwesomeIcon icon={this.props.fontawesome}/>;
        } else {
            throw new Error("Image must be specified! " + this.props.image + " - " + this.props.fontawesome + " - " + this.props.body);
        }
    }


    render() {
        return <div className="TaggedImage">
            {this.getImageBody()}
            <div className="TaggedImage-Body">{this.props.body}</div>
        </div>;
    }

}