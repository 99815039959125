import React, {Component, ReactElement} from "react";
import "./languageDetails.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faStar as faEmptyStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as faFullStar } from '@fortawesome/free-solid-svg-icons'
import { CircleFlag } from 'react-circle-flags'


export interface LanguageDetailsProps {
    name: string,
    rank: number,
    icon: string
}

export default class LanguageDetails extends Component<LanguageDetailsProps> {


    getName() : ReactElement {
        return <h2>{this.props.name}</h2>
    }

    getStars() : ReactElement {
        return <div className="LanguageDetailsStars" key={this.props.name+"-starRanking"}>
            {
                <div>{this.range(this.props.rank).map(i => <FontAwesomeIcon key={this.props.name+"-starfull-"+i} icon={faFullStar}/>)}{this.range(5-this.props.rank).map(i => <FontAwesomeIcon key={this.props.name+"-starempty-"+i} icon={faEmptyStar}/>)}</div>
            }
                </div>;
    }

    range(size:number):ReadonlyArray<number> {
        // @ts-ignore
        return [...Array(size).keys()];
    }


    getIcon() : ReactElement {
        return <div className="LanguageDetails-ImageWrapper" key={this.props.name+"-flag"}><CircleFlag countryCode={this.props.icon} alt={this.props.icon}/></div>
    }

    render() {
        return (<div className="LanguageDetails">
            <div className="LanguageDetails-Content">
                {this.getIcon()}
                {this.getName()}
                {this.getStars()}
            </div>
        </div>)
    }
}