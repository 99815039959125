import React, {Component} from 'react';
import "./progressbar.css"
export interface ProgressBarProps {
    progress : 10|20|30|40|50|60|70|80|90|100;
}

interface ProgressBarState {
    progressWidth: string;
    increment: number;
    currentVal: number;
    targetProgress: number;
    appliedCss: string;
}

export default class ProgressBar extends Component<ProgressBarProps, ProgressBarState> {

    static incrementSteps: number = 50; // we increment this amount of times to make animation look fluid
    static durationSeconds: number = 1; // we finish the animation in this amount of seconds

    interval : number = 0;

    state = {
        progressWidth: "0%",
        increment: 0,
        currentVal: 0,
        targetProgress: 0,
        appliedCss: "ProgressBar-Low"
    }

    constructor(props : ProgressBarProps) {
        super(props);
        this.updateProgressBar = this.updateProgressBar.bind(this);
    }

    static getDerivedStateFromProps(nextProps : ProgressBarProps, prevState : ProgressBarState) : ProgressBarState{
        if (nextProps.progress === prevState.targetProgress) {
            return prevState;
        }
        return {
            progressWidth: 0 + "%",
            increment: nextProps.progress / ProgressBar.incrementSteps,
            currentVal: 0,
            targetProgress : nextProps.progress,
            appliedCss: "ProgressBar-Low",
        }
    }

    componentDidMount() {
        this.interval = window.setInterval(this.updateProgressBar, ProgressBar.durationSeconds*1000/ProgressBar.incrementSteps);
    }


    componentWillUnmount() {
        if (this.interval !== 0) {
            clearInterval(this.interval);
        }
    }

    updateProgressBar() {
        if (this.state.currentVal === this.props.progress || this.state.currentVal > this.props.progress) {
            clearInterval(this.interval);
            this.interval = 0;
        } else {
            const newValue : number =  Math.min(this.state.targetProgress, this.state.currentVal + this.state.increment);
            const { progressWidth, currentVal, appliedCss,...remainder} = this.state;
            this.setState({
                progressWidth: (newValue) + "%",
                currentVal: newValue,
                appliedCss: newValue >= 100 ? "ProgressBar-High" // max affinity
                                            : newValue >= 20 ? "ProgressBar-Medium" // (20-100)% affinity
                                                             : "ProgressBar-Low", // <0-20%>
                ...remainder,
            });
        }
    }


    render() {
        const classesToUse : string = "ProgressBar " + this.state.appliedCss;
        return (<div className={classesToUse}><span style={{width: this.state.progressWidth}}><span style={{width: this.state.progressWidth}}/></span></div>);

    }
}